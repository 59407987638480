$(document).ready(function () {

    function isMobile() {
        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (width <= 767) {
            return true;
        }
        return false;
    }
    
    //initialising accordions//
    function accordion() {
        var $accordions = $('.accordion');
        if ($accordions.length) {
            $accordions.each(function () {
                var accordion = $(this);
                new TBA.AccordionBuilder({
                    $container: accordion
                });
            });
        }
    }
    
    function pushMenu() {
        $('.navbar-toggle').on('click', function (e) {
            e.preventDefault();
            console.log('clicked');
            if ($('.navbar').length > 0) {
                $('body').toggleClass('menu-slider');
            }
        });
    }

    function mobileNav() {   
        var mobileToggle = function () {
            var $parent = $(this).parent();
            var $siblings = $parent.siblings();
            if ($siblings.size() > 0) {
                $(this).closest('li').toggleClass('active');
                $siblings.slideToggle();
                return false;
            }
        };

        var autoNav = false;

        var menuClickHandler = function () {

            var $navigationContainer = $('nav').find('ul');
            $navigationContainer.find('.toggle-icon').off('click', mobileToggle);
            if (window.innerWidth <= 767) {

                $navigationContainer.find('li').children('a').each(function () {
                    var $this = $(this);
                    if ($this.siblings().length > 0 && $this.find('.toggle-icon').length === 0) {
                        $this.append('<span class="toggle-icon" aria-hidden="true"></span>');
                    }
                });

                $navigationContainer.find('.toggle-icon').on('click', mobileToggle);
                $navigationContainer.find('li').removeAttr('style');
                autoNav = false;
            }

        };
        
        //removes activity classes on load from mobile only
        if (isMobile()) {
            $('.nav li').removeClass('active');
        }

        $(document).ready(function () {
            menuClickHandler();
        });
        $(window).on("resize", menuClickHandler);
    }
    
    function accessibilityNav() {
        if (!isMobile()) {
            var el = $("nav");

            /* Make dropdown menus keyboard accessible */

            $("a", el).focus(function() {
                $(this).parents("li").addClass("hover");
            }).blur(function() {
                $(this).parents("li").removeClass("hover");
            });
        }
    }   
    
    function contactForm() {
        if ($('#pirate-forms-contact-message').length > 0) {
            $("#pirate-forms-contact-message").attr("placeholder", "How can we help you? - let us know and we’ll get back to you within 24 hrs");
        }
    }

    accordion();
    pushMenu();
    accessibilityNav();
    mobileNav();
    contactForm();

});
